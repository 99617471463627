import { bool, func, object } from "prop-types";
import { ExportWorkType } from "./ExportWorkType";
import { Totals } from "project-structure";
import { usePresetFieldNames } from "@hooks";
import { Columns } from "project-structure";
import { capitalize } from "@material-ui/core";
import { ExportTotals } from "./ExportTotals";

export const ExportTableSummary = ({
  translate,
  editorStore,
  usePreset,
  allowShowingPrices,
}) => {
  const { currentProjectStructure } = editorStore;

  const {
    workTypes,
    settings,
    visibility,
    hasFixedBreakdownPrice,
    displayFixedBreakdownPrice,
    displayFixedBreakdownPriceMax,
    showMaxFixedBreakdownPrice,
    usesAnyValue,
  } = currentProjectStructure;

  const {
    currencyObj,
    hasTeamMembersCol,
    hasTimeCol,
    useMinMax,
    visibleSummaryColumns,
    visibleTotals,
  } = settings;
  
  const {
    apply,
    hideSummaryTime,
    hideSummaryTeam,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryCost,
  } = visibility;

  const { getFieldName } = usePresetFieldNames(usePreset, editorStore);

  return (
    <div className="table-summary">
      <h3 className="summary">{getFieldName("pt-summary")}</h3>
      <table>
        <thead>
          <tr>
            <th className="summary" style={{ textAlign: "left" }}>
              {getFieldName("pt-work")}
            </th>
            {visibleSummaryColumns.map(({ name }) => {
              switch (name) {
                case Columns.TEAM:
                  if(apply && hideSummaryTeam) return <></>
                  return (
                    <th key={name} className="summary">
                      {getFieldName("pt-teamMembers")}
                    </th>
                  );
                case Columns.TIME:
                  if(apply && hideSummaryTime) return <></>
                  return (
                    <th key={name} className="summary">
                      {getFieldName("pt-timeMta")}
                    </th>
                  );
                case Columns.RATE:
                  if(apply && hideSummaryRate) return <></>
                  return (
                    allowShowingPrices && (
                      <th key={name} className="summary">
                        {`${getFieldName("pt-rate")} (${currencyObj.code})`}
                      </th>
                    )
                  );
                case Columns.HOURS:
                  if(apply && hideSummaryHours) return <></>
                  return (
                    <th key={name} className="summary">
                      {getFieldName("pt-time")}
                    </th>
                  );
                case Columns.COST:
                  if(apply && hideSummaryCost) return <></>
                  return (
                    allowShowingPrices && (
                      <th key={name} className="summary">
                        {getFieldName("pt-cost")}
                      </th>
                    )
                  );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {workTypes
            .filter(workType => !apply || !workType.isHidden)
            .map((workType) => (
              <ExportWorkType
                key={workType.id}
                element={workType}
                currencyObj={currencyObj}
                allowShowingPrices={allowShowingPrices}
                hasTeamMembersCol={hasTeamMembersCol}
                hasTimeCol={hasTimeCol}
                useMinMax={useMinMax}
                translate={translate}
                visibleSummaryColumns={visibleSummaryColumns}
                visibility={visibility}
                settings={settings}
                usesAnyValue={usesAnyValue}
              />
            ))}
          {hasFixedBreakdownPrice && (
            <tr className="system">
              <td
                className="summary-name"
                style={{ textAlign: "left", paddingLeft: 16 }}
                colSpan={
                  2 +
                  (hasTeamMembersCol ? 1 : 0) +
                  (hasTimeCol ? 1 : 0) +
                  (allowShowingPrices ? 1 : 0)
                }
              >
                {getFieldName("pt-fixed")}
              </td>
              {allowShowingPrices && (
                <td>
                  <p className="price">
                    {
                      usesAnyValue &&
                      <>
                        { currencyObj.symbolStart || "" }
                        { displayFixedBreakdownPrice }
                        { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
                      </>
                    }
                    { showMaxFixedBreakdownPrice && "  -  " }
                    { showMaxFixedBreakdownPrice && (
                      <>
                        { currencyObj.symbolStart || "" }
                        { displayFixedBreakdownPriceMax }
                        { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
                      </>
                    )}
                  </p>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
      <div className="total-container">
        {
          visibleTotals
            .map(({name}) => (
              <ExportTotals
                key={name}
                name={name}
                title={getFieldName(`pt-total${capitalize(name)}`)}
                currentProjectStructure={currentProjectStructure}
                translate={translate}
                usesAnyValue={usesAnyValue}
                allowShowingPrices={allowShowingPrices}
              />
            ))
        }
      </div>
    </div>
  );
};

ExportTableSummary.propTypes = {
  editorStore: object,
  usePreset: bool,
  allowShowingPrices: bool,
  translate: func,
};
