import { bool, string, func } from "prop-types";
import { observer } from "mobx-react";
import {
  useEditorCommentStore,
  useEditorWebsocket,
  useFeatureGuardian,
  useProjectEditorStore,
} from "@hooks";
import { universalDateParser, LOCKED_FEATURES } from "@utils";
import { ChatMessage, ChatMessageEditor } from "@components";
import { Divider, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyle from "./ChatThread.style";
import classnames from "classnames";
import { registerProposalCommenterQuery } from "@query";
import { useParams } from "react-router-dom";
import { db } from "@client";

export const ChatThreadPopupContent = observer(({
  path,
  initialMessage,
  allowCommenting,
  internal,
}) => {
  const { projectUuid, projectHash } = useParams();
  const socket = useEditorWebsocket();
  const commentStore = useEditorCommentStore();
  const editorStore = useProjectEditorStore();
  const classes = useStyle();
  const { t } = useTranslation();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.COMMENTS
  );

  const { commenterData, isProposal } = commentStore;

  const handleCommentAdd = async (newMessage, login, mentions) => {
    if(!isProposal) {
      const allowed = await checkAccess();
      if ( !allowed ) return;
    }

    if (!commenterData && login) {
      const d = await registerProposalCommenterQuery(projectHash || projectUuid, login);
      commentStore.setCommenterData(d);
      db.comment.put({
        ...d,
        project: projectHash || projectUuid,
        timestamp: new Date().getTime()
      });
    }
    
    await socket?.requestCommentAdd(
      commentStore.commenterData.uuid,
      path,
      newMessage,
      mentions,
      internal,
      initialMessage?.id,
    );
  };

  const handleCommentEdit = (commentId) => async (newMessage, mentions) => {
    if(!isProposal) {
      const allowed = await checkAccess();
      if ( !allowed ) return;
    }

    await socket?.requestCommentEdit(
      commentId,
      commenterData.uuid,
      path,
      newMessage,
      mentions,
      commentId !== initialMessage?.id ? initialMessage?.id : undefined
    );
  };

  const handleCommentRemove = (commentId, parentId) => async () => {
    if(!isProposal) {
      const allowed = await checkAccess();
      if ( !allowed ) return;
    }

    await socket?.requestCommentRemove(commentId, commenterData.uuid, parentId);
  };

  return (
    <>
      {initialMessage && (
        <Grid item container className={classes.threadContainer}>
          <div className="px-2 pt-2 w-full">
            <ChatMessage
              commentId={initialMessage.id}
              message={initialMessage.body}
              date={universalDateParser(initialMessage.createdAt)}
              ownMessage={initialMessage.userUuid === commenterData?.uuid}
              name={initialMessage.userFullname}
              color={initialMessage.color}
              onRemove={handleCommentRemove(initialMessage.id)}
              alertRemoval isThread
              onEdit={handleCommentEdit(initialMessage.id)}
              projectTeamMembers={editorStore?.teamMembers}
              allowCommenting={allowCommenting}
            />
          </div>
          <Divider variant="fullWidth" orientation="horizontal" />
          {initialMessage.children?.map(
            (
              { id, body, createdAt, userFullname: name, color, userUuid },
              i
            ) => {
              return (
                <div
                  key={`comment${id}`}
                  className={classnames(
                    "px-2 pt-2 w-full",
                    classes.responseContainer, {
                    [classes.messageBordered]: initialMessage.children
                      && i < initialMessage.children?.length - 1,
                  })}
                >
                  <ChatMessage
                    commentId={id}
                    message={body}
                    date={universalDateParser(createdAt)}
                    ownMessage={userUuid === commenterData?.uuid}
                    name={name}
                    color={color}
                    onRemove={handleCommentRemove(id, initialMessage.id)}
                    onEdit={handleCommentEdit(id)}
                    projectTeamMembers={editorStore?.teamMembers}
                    allowCommenting={allowCommenting}
                  />
                </div>
              );
            }
          )}
        </Grid>
      )}
      <div
        className={classnames({
          [classes.editorContainer]: true,
          [classes.bordered]: initialMessage?.children?.length,
        })}
      >
        {
          allowCommenting &&
          <ChatMessageEditor
            onCommit={handleCommentAdd}
            placeholder={t("views.editor.comments.placeholder")}
            requireAuth={!commenterData}
            projectTeamMembers={editorStore?.teamMembers}
          />
        }
        {
          !allowCommenting && !initialMessage &&
          <span className="ml-2 my-2 transparent-2">
            {t(`views.editor.comments.${internal ? "no_internal" : "no_external"}`)}
          </span>
        }
      </div>
      <FeatureAlert />
    </>
  );
});

ChatThreadPopupContent.propTypes = {
  path: string.isRequired,
  allowCommenting: bool,
  onClose: func,
};
