import { useMemo, useState } from "react";
import {
  any,
  bool,
  string,
  func,
  arrayOf,
  instanceOf,
  oneOfType,
  object,
} from "prop-types";
import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {useStores} from "@hooks";
import { HtmlParser } from "@utils";
import { Alert, Avatar, ChatMessageEditor } from "@components";
import { Pencil, Trash } from "@assets";
import { Clear } from "@material-ui/icons";

export const ChatMessage = ({
  message,
  name,
  color,
  date,
  ownMessage,
  onRemove,
  onEdit,
  alertRemoval,
  projectTeamMembers,
  allowCommenting,
  isThread,
}) => {
  const { t } = useTranslation();
  const { userStore } = useStores();

  const [isDeleting, setDeletingState] = useState(false);
  const [isSending, setSendingState] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [alertVisible, showAlert] = useState(false);

  const { isAdmin } = userStore;

  const allowRemoval = useMemo(() => (
    allowCommenting && (ownMessage || isAdmin) && !!onRemove
  ), [allowCommenting, ownMessage, isAdmin]);

  const allowEdition = useMemo(() => (
    allowCommenting && ownMessage && !!onEdit
  ), [allowCommenting, ownMessage]);

  const useRemoveAlert = useMemo(() => (
    alertRemoval || (!ownMessage && isAdmin)
  ), [alertRemoval, ownMessage, isAdmin]);

  const parsedDate = useMemo(
    () =>
      date
        ? new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60)
        : new Date(),
    []
  );

  const toggleAlert = () => showAlert(!alertVisible);

  const removeMessage = async () => {
    showAlert(false);
    if (!isDeleting) {
      setDeletingState(true);
      await onRemove();
      setDeletingState(false);
    }
  };

  const updateMessage = async (newMessage, login, mentions) => {
    if (!isSending) {
      setSendingState(true);
      await onEdit(newMessage, mentions);
      setSendingState(false);
      setEditMode(false);
    }
  };

  return (
    <Grid item container role="listitem" className="p-2">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className="mb-3"
      >
        <Grid item container alignItems="center" className="w-max">
          <Avatar fullname={name || ""} color={color} size={32} />
          <Grid item container direction="column" className="ml-4 py-0 w-max">
            <p className="text-sm font-bold">
              {!ownMessage ? name : t("common.you")}
            </p>
            <p className="text-xs transparent-2">
              {format(parsedDate, "d MMM, yyyy")}
            </p>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" className="w-max">
          {allowRemoval && (
            <Grid item>
              <IconButton
                size="small"
                onClick={useRemoveAlert ? toggleAlert : removeMessage}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <CircularProgress size={14} />
                ) : (
                  <Trash className="text-sm" color="error" />
                )}
              </IconButton>
            </Grid>
          )}
          {allowEdition && (
            <Grid item>
              <IconButton
                size="small"
                onClick={() => setEditMode(!editMode)}
                disabled={isSending}
              >
                {editMode ? (
                  <Clear className="text-sm" />
                ) : (
                  <Pencil className="text-sm" />
                )}
              </IconButton>
            </Grid>
          )}
          {useRemoveAlert && (
            <Alert
              isOpen={alertVisible}
              title={t(`views.editor.comments.remove_alert.${isThread ? "thread" : "message"}`)}
              onCancel={toggleAlert}
              onAccept={removeMessage}
              acceptText={t("common.confirm_remove")}
              cancelText={t("common.cancel_remove")}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        // wrap="nowrap"
        alignItems="center"
        aria-label="message content"
        className="ql-root"
      >
        {
          editMode
            ? <ChatMessageEditor
              onCommit={updateMessage}
              placeholder={t("views.editor.comments.placeholder")}
              defaultValue={message}
              projectTeamMembers={projectTeamMembers}
            />
            : HtmlParser(message)
        }
      </Grid>
    </Grid>
  );
};

ChatMessage.propTypes = {
  message: oneOfType([string, arrayOf(any)]),
  name: string.isRequired,
  color: string,
  ownMessage: bool,
  date: instanceOf(window.Date),
  onRemove: func,
  onEdit: func,
  alertRemoval: bool,
  allowCommenting: bool,
  projectTeamMembers: arrayOf(object),
};
